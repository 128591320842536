import React from "react";
import "./SalesEnquiryPopup.css";
import whatsappIcon from "../assets/whatsapp.png";
import callIcon from "../assets/phone-call.png";

const SalesEnquiryPopup = ({ onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <button className="close-popup-btn-sales" onClick={onClose}>
          &times;
        </button>
        <h3 className="popup-message-sales">Connect with us below:</h3>
        <div className="popup-icons">
          {/* WhatsApp Icon */}
          <a
            href="https://wa.me/8655975131?text=Hello%20Pare%20Team%2C%20I%20have%20a%20sales%20enquiry."
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={whatsappIcon} alt="WhatsApp" className="popup-icon" />
          </a>
          {/* Call Icon */}
          <a href="tel:+91 8655975131">
            <img src={callIcon} alt="Call" className="popup-icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SalesEnquiryPopup;
