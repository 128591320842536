import React, { useState } from "react";
import "./RequestBrochure.css";
import { CircleLoader } from "react-spinners"; // Importing loader
import logo from "../assets/logo.png";
import nextIcon from "../assets/next.png";
// import prevIcon from "../assets/previous.png"; // Add a previous button icon
import { FaWhatsapp, FaPhoneAlt } from "react-icons/fa";


const RequestBrochure = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    location: "",
    phoneNumber: "",
    email: "",
    requirements: ""
    // companyName: "",
    // natureOfEnquiry: "",
    // solutionLookingFor: "",
    // specialRequirements: "",
  });

  const [step, setStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null); // For pop-up error messages
  const [isLoading, setIsLoading] = useState(false); // For loader
  const [isSuccess, setIsSuccess] = useState(false); // Track submission success

  const steps = [
    { label: "What's your name?", placeholder: "Full Name (required)", name: "fullName", type: "text" },
    { label: "What's your location?", placeholder: "City (required)", name: "location", type: "text" },
    { label: "What's your phone no.?", placeholder: " 0000 000 000 (required)", name: "phoneNumber", type: "number" },
    { label: "What's your e-mail Id?", placeholder: "For eg.: abc@gmail.com (required)", name: "email", type: "email" },
    { label: "State your requirement", name: "requirements", type: "select", options: ["Personal Requirement", "Commercial Requirement"], // Radio button options
    },
    // { label: "What's your Company Name?", placeholder: "Company Name (optional)", name: "companyName", type: "text" },
    // { label: "Nature of Enquiry", placeholder: "", name: "natureOfEnquiry", type: "select", options: ["Personal use", "Stockist", "Dealer", "Builder", "Architect", "Contractor", "Others"] },
    // { label: "What Solutions are you looking for?", placeholder: "", name: "solutionLookingFor", type: "select", options: ["Pare Wall Solution", "Pare Ceiling Solution", "Pare Facade Solution"] },
    // { label: "Any additional Comments", placeholder: "(optional)", name: "specialRequirements", type: "text" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNext = () => {
    const currentField = steps[step].name;
    const currentValue = formData[currentField];

    if (!currentValue && currentField !== "companyName" && currentField !== "specialRequirements") {
      setErrorMessage(`Please fill in your ${steps[step].placeholder} before proceeding.`);
      return;
    }

    if (currentField === "email" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(currentValue)) {
      setErrorMessage("Please enter a valid email address.");    
      return;
    }

    if (currentField === "phoneNumber" && !/^\+?\d{10,15}$/.test(currentValue)) {
      setErrorMessage("Please enter a valid phone number.");
      return;
    }

    setErrorMessage(null);
    if (step < steps.length - 1) setStep(step + 1);
  };

  // const handlePrevious = () => {
  //   if (step > 0) setStep(step - 1);
  // };

  const handleSubmit = async () => {
    // Exclude optional fields from validation
  const requiredFields = ["fullName", "location", "phoneNumber", "email" , "requirements"];
  // "natureOfEnquiry", "solutionLookingFor"

  for (const field of requiredFields) {
    if (!formData[field]) {
      setErrorMessage(`Please fill in your ${field} before submitting.`);
      return;
    }
  }

    setIsLoading(true);

    const payload = {
      fullName: formData.fullName,
      location: formData.location,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      requirements: formData.requirements,
      // companyName: formData.companyName,
      // natureOfEnquiry: formData.natureOfEnquiry,
      // otherEnquiry: formData.otherEnquiry,
      // solutionLookingFor: formData.solutionLookingFor,
      // specialRequirements: formData.specialRequirements,
    };

    // const scriptURL = "https://landingpage-serve.onrender.com/google-script-brochure";
    // const scriptURL = "http://localhost:5000/google-script-brochure"
    const scriptURL = "https://api.testwebsite25.in/google-script-brochure";

    try {
      const response = await fetch(scriptURL, {
        method: "POST",
        headers: { "Content-Type" : "application/json" },
        body: JSON.stringify(payload),
      });

      const result = await response.json();
      if (response.ok && result.success) {
        setIsLoading(false);
        setIsSuccess(true);
      } else {
        setIsLoading(false);
        setErrorMessage(`Failed to submit form: ${result.message || "Unknown error"}`);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  return (
    <div className="request-brochure">
      <div className="logo-container">
        <img src={logo} alt="Company Logo" className="logo" />
      </div>

      {isSuccess ? (
        <div className="success-message">
          <h2>🎉 Thank you for reaching out! 🎉</h2>
          <p>Our Customer Executive will connect with you shortly. 🚀✨</p>
          <p className="contact-text">
            For more information or in case of urgency, connect with us here:
          </p>
          <div className="contact-info">
            <a href="https://wa.me/1234567890" target="_blank" rel="noopener noreferrer" className="contact-icon">
              <FaWhatsapp size={20} color="#25D366" /> WhatsApp
            </a>
            <a href="tel:+1234567890" className="contact-icon">
            <FaPhoneAlt size={20} color="#28a745" /> +91 99999 99999
            </a>
          </div>
        </div>
      ) : (
        <div className="form-container-brochure">
          <h2>Request Brochure</h2>
          <p>{steps[step].label}</p>
          
          { steps[step].type === "text" || steps[step].type === "email" || steps[step].type === "number" ?(
            <input
              className="form-input"
              type={steps[step].type}
              name={steps[step].name}
              placeholder={steps[step].placeholder}
              value={formData[steps[step].name]}
              onChange={handleChange}
          />
          ) :  steps[step].type === "select" ? (
            <select
              className="form-input"
              name={steps[step].name}
              value={formData[steps[step].name]}
              onChange={handleChange}
            >
              <option
                value=""
              >Select...</option>
              {steps[step].options.map((option, index) => (
                <option key = {index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          ) : null}

          {/* //   steps[step].type === "select" ?(
          //   <select
          //     className="form-input"
          //     name={steps[step].name}
          //     value={formData[steps[step].name]}
          //     onChange={handleChange}
          //   >
          //     <option value="">Select...</option>
          //     {steps[step].options.map((option, index) =>(
          //       <option key={index} value={option}>
          //         {option}
          //       </option>
          //     ))}
          //   </select>
          // ) : null}

          // {steps[step].name === "natureOfEnquiry" && formData.natureOfEnquiry === "Others" && (
          //   <input
          //     className="form-input"
          //     type="text"
          //     name="otherEnquiry"
          //     placeholder="Please specify your enquiry"
          //     value={formData.otherEnquiry}
          //     onChange={handleChange}
          //   />
          // )} */}
          
          <div className="form-navigation">
            {/* {step > 0 && (
              <button className="prev-btn-brochure" onClick={handlePrevious}>
                <img src={prevIcon} alt="Previous" />
              </button>
            )} */}
            {step < steps.length - 1 ? (
              <button className="next-btn-brochure" onClick={handleNext}>
                <img src={nextIcon} alt="Next" />
              </button>
            ) : (
              <button className="submit-btn-brochure" onClick={handleSubmit}>
                {isLoading ? <CircleLoader size={25} color="#fff" /> : "Submit"}
              </button>
            )}
          </div>
        </div>
      )}

      {errorMessage && (
        <div className="popup-message">
          <p>{errorMessage}</p>
          <button onClick={() => setErrorMessage(null)}>Close</button>
        </div>
      )}
    </div>
  );
};

export default RequestBrochure;
