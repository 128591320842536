import React, { useState } from "react";
import "./CustomerSupport.css";
import { CircleLoader } from "react-spinners"; // Importing loader
import { useNavigate } from "react-router-dom"; 
import logo from "../assets/logo.png";
import nextIcon from "../assets/next.png";
// import prevIcon from "../assets/previous.png"; // Add a previous button icon

const RequestBrochure = () => {
  
  const [formData, setFormData] = useState({
    fullName: "",
    location: "",
    email: "",
    phoneNumber: "",
    requirements: ""
    // companyName:"",
    // natureOfEnquiry:"",
    // solutionLookingFor:"",
    // appropriateTimeToCall:"",
    // productBriefing:"",
    // additionalInformation:""
  });

  const [step, setStep] = useState(0);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // For loader
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const navigate = useNavigate();

  const steps = [
    { label: "What's your name?", placeholder: "Full Name (required)", name: "fullName", type: "text" },
    { label: "What's your location?", placeholder: "City", name: "location", type: "text" },
    { label: "What's your e-mail Id?", placeholder: "For eg.: abc@gmail.com", name: "email", type: "email" },
    { label: "What's your phone no.?", placeholder: " 0000 000 000", name: "phoneNumber", type: "number" },
    { label: "Choose your purpose", name: "requirements", type: "select", options: ["For Your Home", "For Your Business"], // Radio button options
  },
    // { label: "What's your Company Name?", placeholder: "Company Name (optional)", name: "companyName", type: "text" },
    // { label: "Nature of Enquiry", placeholder: "", name: "natureOfEnquiry", type: "select", options: ["Personal use", "Stockist", "Dealer", "Builder", "Architect", "Contractor", "Others"] },
    // { label: "What Solutions are you looking for?", placeholder: "", name: "solutionLookingFor", type: "select", options: ["Pare Wall Solution", "Pare Ceiling Solution", "Pare Facade Solution"] },
    // { label: "Appropriate time to call you", placeholder: "", name: "appropriateTimeToCall", type: "select", options: ["Morning", "Afternoon", "Evening"]},
    // { label: "Are you interested in a free presntation along with a product briefing?", placeholder: "", name: "productBriefing", type: "select", options: ["Yes", "No"]},
    // { label: "Any additional information or special requests?", placeholder: "", name: "additionalInformation", type: "text" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNext = () => {
    const currentField = steps[step].name;
    const currentValue = formData[currentField];

   if (!formData[steps[step].name] && currentField !== "companyName" && currentField !== "additionalInformation") {
      setMessage(`Please fill in your ${steps[step].placeholder} before proceeding.`);
      setIsSuccessMessage(false);
      return;
    }

    if (currentField === "email" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(currentValue)) {
      setMessage("Please enter a valid email address.");
      setIsSuccessMessage(false);
      return;
    }
  
    if (currentField === "phoneNumber" && !/^\+?\d{10,15}$/.test(currentValue)) {
      setMessage("Please enter a valid phone number.");
      setIsSuccessMessage(false);
      return;
    }

    setMessage(null);
    if (step < steps.length - 1) setStep(step + 1);
  };

  // const handlePrevious = () => {
  //   if (step > 0) setStep(step - 1);
  // };

  const handleSubmit = async () => {

    // Exclude optional fields from validation
  const requiredFields = ["fullName", "location", "phoneNumber", "email", "requirements"];
  // "natureOfEnquiry", "solutionLookingFor", "appropriateTimeToCall", "productBriefing"

  for (const field of requiredFields) {
    if (!formData[field]) {
      setMessage(`Please fill in your ${field} before submitting.`);
      setIsSuccessMessage(false)
      return;
    }
  }

    setIsLoading(true); // Show loader

    // Prepare the form data to send to Google Sheets (API)
    const payload = {
      fullName: formData.fullName, 
      location: formData.location,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      requirements: formData.requirements,
      // companyName: formData.companyName,
      // natureOfEnquiry: formData.natureOfEnquiry,
      // solutionLookingFor: formData.solutionLookingFor,
      // appropriateTimeToCall: formData.appropriateTimeToCall,
      // productBriefing: formData.productBriefing,
      // additionalInformation: formData.additionalInformation,
    };

    // const scriptURL = "https://landingpage-serve.onrender.com/google-script-support"; // Endpoint for Google Sheets
    // const scriptURL = "http://localhost:5000/google-script-support";
    const scriptURL = "https://api.testwebsite25.in/google-script-support";

    try {
      const response = await fetch(scriptURL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const result = await response.json();
      if (response.ok && result.success) {
        setIsLoading(false);
        setMessage(
          "🎉 Thank you for reaching out! Our Customer Executive will connect with you shortly.🚀✨"
        ); // Success message
        setIsSuccessMessage(true);
      } else {
        setIsLoading(false); // Hide loader
        setMessage(`Failed to submit form: ${result.message || "Unknown error"}`);
        setIsSuccessMessage(false);
      }
    } catch (error) {
      setIsLoading(false); // Hide loader
      setMessage("An error occurred. Please try again.");
      setIsSuccessMessage(false);
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  return (
    <div className="customer-support">
      <div className="logo-container">
        <img src={logo} alt="Company Logo" className="logo" />
      </div>

      <div className="customer-support-form">
        <h2>Sales Enquiry</h2>
        <p>{steps[step].label}</p>

        {steps[step].type === "text" || steps[step].type === "email" || steps[step].type === "number" ? (
            <input
            className="form-input"
            type={steps[step].type}
            name={steps[step].name}
            placeholder={steps[step].placeholder}
            value={formData[steps[step].name]}
            onChange={handleChange}
          />
        ) : steps[step].type === "select" ? (
          <select
              className="form-input"
              name={steps[step].name}
              value={formData[steps[step].name]}
              onChange={handleChange}
            >
              <option
                value=""
              >Select...</option>
              {steps[step].options.map((option, index) => (
                <option key = {index} value={option}>
                  {option}
                </option>
              ))}
            </select>
        ) : null}
        
        {/* steps[step].type === "select" ? (
            <select
              className="form-input"
              name={steps[step].name}
              value={formData[steps[step].name]}
              onChange={handleChange}
            >
              <option
                value=""
              >Select...</option>
              {steps[step].options.map((option, index) => (
                <option key = {index} value={option}>
                  {option}
                </option>
              ))}
            </select>
        ) : null}

        {steps[step].name === "natureOfEnquiry" && formData.natureOfEnquiry === "Others" && (
          <input
          className="form-input"
          type="text"
          name="otherEnquiry"
          placeholder="Please specify your enquiry"
          value={formData.otherEnquiry}
          onChange={handleChange}
          />
          )
        } */}
          
        <div className="form-navigation">
          {/* {step > 0 && (
            <button
              className="prev-btn-brochure"
              onClick={handlePrevious}
            >
              <img src={prevIcon} alt="Previous" />
            </button>
          )} */}
          {step < steps.length - 1 ? (
            <button
              className="next-btn-brochure"
              onClick={handleNext}
            >
              <img src={nextIcon} alt="Next" />
            </button>
          ) : (
            <button
              className="submit-btn-customer"
              onClick={handleSubmit}
            >
              {isLoading ? <CircleLoader size={25} color="#fff" /> : "Submit"}
            </button>
          )}
        </div>
        {/* Pop-up Message */}
        {message && (
          <div className="popup-message">
           <p>{message}</p>
            <button onClick= {() => {
              if(isSuccessMessage){
              navigate("/");
            }
            setMessage(null);
            }}>Close</button>
           </div>
        )}
      </div>
    </div>
  );
};

export default RequestBrochure;
