import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./components/LandingPage";
import RequestBrochure from "./components/RequestBrochure";
import CustomerSupport from "./components/CustomerSupport"; // Existing contact form

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/request-brochure" element={<RequestBrochure />} />
        <Route path="/customer-support" element={<CustomerSupport />} />
      </Routes>
    </Router>
  );
};

export default App;
