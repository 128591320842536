import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import navigation
import "./LandingPage.css";
import logo from "../assets/logo.png";
// import instagramIcon from "../assets/insta-logo.png";
// import whatsappIcon from "../assets/Whatsapp-logo.png";
// import facebookIcon from "../assets/fb-logo.png";
import ContactForm from "./ContactForm"; // Import the ContactForm component
import SalesEnquiryPopup from "./SalesEnquiryPopup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWhatsapp, faFacebook } from '@fortawesome/free-brands-svg-icons'; // Import Font Awesome brand icons

const LandingPage = () => {
  const [showSalesPopup, setShowSalesPopup] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false); // State to control form visibility
  const navigate = useNavigate();

  const handleRequestBrochureClick = () => {
    navigate("/request-brochure"); // Navigate to the Request Brochure page
  };

  const handleCustomerSupportClick = () => {
    navigate("/customer-support");
    // setShowContactForm(true); // Show the contact form
    // document.body.classList.add("modal-active"); // Add class to blur the background
  };

  const handleSalesEnquiryClick = () => {
    setShowSalesPopup(true);
  };

  const handleClosePopup = () => {
    setShowSalesPopup(false);
  };

  const handleCloseForm = () => {
    setShowContactForm(false); // Hide the contact form
    document.body.classList.remove("modal-active"); // Remove background blur
  };

  return (
    <div className="landing-page">
      {/* Logo section */}
      <div className="logo-container">
        <img src={logo} alt="Company Logo" className="logo" />
      </div>

      {/* Buttons section */}
      <div className="button-container">
        <button className="action-button" onClick={handleRequestBrochureClick}> 
          Request Brochure
        </button>
        <button className="action-button" onClick={handleCustomerSupportClick}>Sales Enquiry</button>
        <button className="action-button"  onClick={handleSalesEnquiryClick}>Customer Support</button>
      </div>

      {/* Footer section */}
      <div className="footer-container">
        <div className="social-icons">
          {/* Instagram */}
          <a
            href="https://www.instagram.com/pareindia/"
            target="_blank"
            rel="noopener noreferrer"
          >
             <FontAwesomeIcon icon={faInstagram} className="social-icon fa-icon" />
          </a>

          {/* WhatsApp */}
          <a
            href="https://wa.me/8655975131?text=Hello%20Pare%20Team%2C%20I%20have%20an%20enquiry."
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faWhatsapp} className="social-icon fa-icon" />
          </a>

          {/* Facebook */}
          <a
            href="https://www.facebook.com/Pareinnov/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook} className="social-icon fa-icon" />
          </a>
        </div>
        <p className="website-link">
          <a
            href="https://www.pareindia.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.pareindia.com
          </a>
        </p>
      </div>

      {/* Sales Enquiry Pop-up */}
      {showSalesPopup && <SalesEnquiryPopup onClose={handleClosePopup} />}

      {/* Contact Form Pop-Out */}
      {showContactForm && (
        <div className="contact-form-overlay">
          <div className="contact-form-wrapper">
            <button className="close-form-btn" onClick={handleCloseForm}>
              &times;
            </button>
            <ContactForm />
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingPage;
