import React, { useState } from "react";
import "./ContactForm.css";
import nextIcon from "../assets/next-button.png"; // Path to the Next button icon
import prevIcon from "../assets/previous.png"; // Path to the Previous button icon

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    location: "",
    email: "",
    phoneNumber: "",
  });

  const [step, setStep] = useState(0); // Track the current step

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNext = () => {
    if (step < 3) setStep(step + 1);
  };

  const handlePrevious = () => {
    if (step > 0) setStep(step - 1);
  };

  const renderSteps = () => {
    const steps = [
      {
        // label: "Full Name:",
        name: "fullName",
        type: "text",
        placeholder: "Your Name"
      },
      {
        // label: "Location:",
        name: "location",
        type: "text",
        placeholder: "Your Location"
      },
      {
        // label: "Email:",
        name: "email",
        type: "email",
        placeholder: "Your Email"
      },
      {
        // label: "Phone Number:",
        name: "phoneNumber",
        type: "tel",
        placeholder: "Your Phone Number"
      },
    ];

    return steps.map((stepItem, index) => (
      <div
        className="form-step"
        key={index}
        style={{
          transform: `translateX(${(index - step) * 100}%)`, // Dynamically position steps
        }}
      >
        <label>{stepItem.label}</label>
        <input
          type={stepItem.type}
          name={stepItem.name}
          value={formData[stepItem.name]}
          onChange={handleChange}
          placeholder={stepItem.placeholder}
          required
        />
      </div>
    ));
  };

  return (
    <div className="contact-form-container">
      <h2>Customer Support</h2>
      <div className="form-step-container">{renderSteps()}</div>
      <div className="form-navigation">
        {step > 0 && (
          <button className="prev-btn" onClick={handlePrevious}>
          <img src={prevIcon} alt="Previous" className="nav-icon" />
        </button>
        )}
        {step < 3 ? (
          <button className="next-btn" onClick={handleNext}>
          <img src={nextIcon} alt="Next" className="nav-icon" />
        </button>
        ) : (
          <button className="submit-btn" onClick={() => alert("Form Submitted!")}>
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
